import React, { Fragment, useState, useEffect } from 'react';
import {TextField} from '@material-ui/core';
import { uploadDoc, updatetargetVendors, gettargetVendors, updateBrouchere } from '../../actions/asyncActions';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



const Loc = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [vendors, setTargetVendors ] = useState([]);
  const [locdoc , setLocdoc] = useState([]);
  const Id = props.vendorid;

    const [numPages, setNumPage] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [deletionreason, setDeletionreason] = useState('');
    const [values, setValues] = useState({});
    const [reasonmodal, setReasonmodal] = useState();

  useEffect( () => {
    gettargetVendors()
      .then(resp => setTargetVendors(resp.data))
    }, []);


const handleUploadInvoice = () => {
   setLocdoc(document.getElementById('fileinvoice').files[0]);
 }

 const user = JSON.parse(localStorage.getItem("m_users"));
 const user_id = user && user.id || [];

 const filterDoc = vendors.find(s => s.id === Id);
    const docFile = filterDoc && filterDoc.locpath;
   const aa = docFile || [];
    const filenamew = aa.slice(5);
    //const Path = 'http://172.105.41.149:9002'
    const Path = 'https://nfe.nu-pie.com';
    //const Path = `http://127.0.0.1:9002`;
    
    const docPath = `${Path}${docFile}`
    
    
  
     const onDocumentLoadSuccess = ({ numPages }) => {
       setNumPage(numPages);
     }

      const downloadFile = (dataurl, filename) => {
    //  dataurl = 'data:text/html,HelloWorld!';
      // filename = 'helloWorld.txt';
      const url = 'http://172.105.41.149:9002/Doc/'
      const namefile = filenamew;
       dataurl = `${url}${namefile}`;
       console.log("dataurl", dataurl);
         filename = filenamew;
        // var a = document.createElement("a");
        // a.href = dataurl;
        // a.setAttribute("download", filename);
        // a.click();

        fetch(dataurl).then(function(t) {
          return t.blob().then((b)=>{
              var a = document.createElement("a");
              a.href = URL.createObjectURL(b);
              a.setAttribute("download", filename);
              a.click();
          }
          );
      });
   }

   const handleSubmit = async (event) => {
      const formData = new FormData()
      formData.append( 'file', locdoc )
      const inv = await uploadDoc(formData);
      const aaa = inv.data.filename;
      const desti = inv.data.destination;
      console.log("desti", desti);
      const invpath = "/Doc/" + aaa;

      const data = {
        id : Id,
        locpath : invpath,
      }
         updatetargetVendors(data)
        .then(resp => {
            })
            .catch(err => {
              console.log("Error", err);
    })   
            window.location.reload();
  }

  const openReason = () => {
     setReasonmodal(!reasonmodal)
    }

  const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

   const goToPrevPage = () => {
      setPageNumber(pageNumber - 1);
     }
     const goToNextPage = () => {
      setPageNumber(pageNumber + 1);
     } 


     const  handleReasonSubmit= () => {
      const data = {
        id : Id,
        doc : docFile,
        logedinuser: user_id,
        reason : values.reason,
      }
      const path = {
        id : Id,
        locpath: '',
      }

      updateBrouchere({data, path}) 
      .then(resp => {

      })
      .catch(err => {
        console.log("Error", err);
      })

       setReasonmodal(!reasonmodal)
      window.location.reload();
     }


  return (

    <Fragment>

    {docFile ?
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-xl-6">
                
                  <nav>
                    
                    <button className="btn btn-light btn-sm" onClick={goToPrevPage}>Prev</button>&nbsp;&nbsp;
                    <button className="btn btn-secondary btn-sm" onClick={goToNextPage}>Next</button>&nbsp;&nbsp;
                    <button className="btn btn-primary btn-sm" onClick={openReason}>Delete Document</button>&nbsp;&nbsp;
                     <button className="btn btn-primary btn-sm" onClick={downloadFile }>Download Document</button>
                  </nav>
                  <Document
                    file={`${docPath}`}
                    onLoadSuccess={onDocumentLoadSuccess}
                   >
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <p>Page {pageNumber} of {numPages}</p>
                  
                </div>
              </div>
            </div>

            :
                <div>
       <div className="col-md-6">
                  <label className="col-form-label text-body" htmlFor="message-text">Attach Doc:</label>
                      <TextField id="fileinvoice" type="file" margin="dense" variant="outlined" onChange={handleUploadInvoice} fullWidth/>
                     </div>
           <div className="col-md-4">
             <button className="btn btn-pill btn-secondary-gradien btn-sm" type="button" onClick={handleSubmit}>Submit</button>
    </div>
    </div>
           
           }


           <Modal isOpen={reasonmodal} toggle={openReason}>
                <ModalHeader toggle={openReason}>Reason</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Reason For Delete:</label>
                              <input className="form-control" name="reason" type="text" placeholder="Reason For Delete" 
                               value={values.reason} onChange={handleChange}/>
                            </div>
                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={openReason}>Cancel</Button>
                    <Button color="primary" onClick={handleReasonSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>
        </Fragment>

    );


}


export default Loc;
