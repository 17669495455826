import axios from 'axios';

let baseURL = 'http://127.0.0.1:9002/api';

if(process.env.NODE_ENV !== "development") {
  baseURL = "/api";
}
const instance = axios.create({
   withCredentials: true
 });

const makeRequest = (type, url, data) => {
  return instance[type](baseURL+url, data)
}

export const signinUser2 = (email, password) => {
  return makeRequest('post', '/login', {email, password})
  .then(resp => {
    if(resp.data){
      localStorage.setItem("s_token", resp.data.token);
      localStorage.setItem("m_users", JSON.stringify(resp.data.user));
      return resp.data;
    }
  })
}


/*** Users ***/
export const getAllUser = () => {
 return makeRequest('get', '/users');
}
export const getUser = () => {
  return makeRequest('get', '/users');
}

export const getUserdetails = (user_id) => {
  return makeRequest('get', `/users/${user_id}`);
}
export const postUsers = (data) => {
  return makeRequest('post', '/users', data);
}
export const editUsers = (data) => {
  return makeRequest('put', '/users', data);
}
export const updateUsers = (data) => {
  return makeRequest('put', '/users', data);
}
export const deleteUsers = (data) => {
  return makeRequest('delete', '/users', {data: data});
}

// export const getip = () => {
//   return makeRequest('get', '/getip');
// }





/* Forgot Password  */

export const sendEmaillink = (data) => {
return makeRequest('post', '/forgotpassword', data);
}

export const sendresettoken = (data) => {
return makeRequest('post', '/resetpassword', data);
}

export const updatepaswordviaemail = (data) => {
  return makeRequest('post', '/updatepasswordviaemail', data)
}


/* Target Vendors List */ 

export const gettargetVendors = () => {
  return makeRequest('get', '/targetvendors');
 }

export const posttargetVendors = (data) => {
  return makeRequest('post', '/targetvendors', data);
}
// export const edittargetVendors = (data) => {
//   return makeRequest('put', '/targetvendors', data);
// }
export const updatetargetVendors = (data) => {
  return makeRequest('put', '/targetvendors', data);
}
export const deletetargetVendors = (data) => {
  return makeRequest('delete', '/targetvendors', {data: data});
}

export const downloadcsv = () => {
  return makeRequest('get' , '/downloadcsv')
}

/* Classifications */ 

export const getclassifications = () => {
  return makeRequest('get', '/classifications');
 }

export const postclassifications = (data) => {
  return makeRequest('post', '/classifications', data);
}
// export const editclassifications = (data) => {
//   return makeRequest('put', '/classifications', data);
// }
export const updateclassifications = (data) => {
  return makeRequest('put', '/classifications', data);
}
export const deleteclassifications = (data) => {
  return makeRequest('delete', '/classifications', {data: data});
}


/* SubClassifications */ 

export const getsubclassifications = () => {
  return makeRequest('get', '/subclassifications');
 }

export const postsubclassifications = (data) => {
  return makeRequest('post', '/subclassifications', data);
}
export const editsubclassifications = (data) => {
  return makeRequest('put', '/subclassifications', data);
}
export const updatesubclassifications = (data) => {
  return makeRequest('put', '/subclassifications', data);
}
export const deletesubclassifications = (data) => {
  return makeRequest('delete', '/subclassifications', {data: data});
}
export const bulkSubClassificationUpload = (data) => {
  return makeRequest('post', '/uploadsubclassifications', data);
}



/* VendorMapping */ 

export const getvendormappings = () => {
  return makeRequest('get', '/vendormappings');
 }

 export const postvendormap = (data) => {
  return makeRequest('post', '/vendormappings', data)
 }


/* Doc Uploader  */

export const uploadDoc = (data) => {
  return makeRequest('post', '/document', data)
}

export const updateBrouchere = (data) => {
  return makeRequest('put', '/brouchereupdate', data);
}













